import type { Auth0VueClient } from '@auth0/auth0-vue'
import { useAuth0 } from '@auth0/auth0-vue'

import type { NuxtApp } from '#app'

async function goToSignIn(auth0: Auth0VueClient, toFullPath: string) {
  let returnTo = getBaseUrl() + '/signin'
  if (toFullPath !== '/') {
    returnTo += `?redirect=${encodeURIComponent(toFullPath)}`
  }

  const sessionStore = useSessionStore()
  await sessionStore.logOut(auth0, returnTo)
}

/**
 * Auth0のセッションをチェックし、ログインしていなければ/signinにリダイレクトする
 * 注意: この処理はサーバーサイドで実行されないので、SSRだと最初のページでは実行されない。
 */

export default defineNuxtRouteMiddleware(async (to, _from) => {
  const nuxtApp = useNuxtApp() as NuxtApp
  const consola = useConsola()

  consola.debug('[authenticated] start')

  if (import.meta.client) {
    consola.debug('[authenticated] client mode')
    const auth0 = useAuth0()
    consola.debug('[authenticated] checking session')
    await auth0.checkSession()
    if (!auth0.isAuthenticated.value) {
      consola.debug('[authenticated] Not authenticated. Redirect to /signin')
      await goToSignIn(auth0, to.fullPath)
      return
    }

    // HTTPクライアントやアップローダーにtokenをセットする
    try {
      consola.debug('[authenticated] get access token')
      const token = await auth0.getAccessTokenSilently()
      nuxtApp.$httpClient.setHeader('X-Authorization', 'Bearer ' + token)
      nuxtApp.$uploader.setHeader('X-Authorization', 'Bearer ' + token)
      consola.debug('[authenticated] set token successfully')
    } catch {
      consola.debug('[authenticated] Not authenticated. Redirect to /signin')
      await goToSignIn(auth0, to.fullPath)
      return
    }

    // Auth0トークンが取得できたら、次はstateに必要な情報が揃っているかチェック
    // user/tenantがない場合は、取得しにいく
    const sessionStore = useSessionStore()
    try {
      if (!sessionStore.isAuthenticated) {
        consola.debug('Not authenticated. Try to refresh token')
        await sessionStore.refreshUserData()
      }
    } catch (e) {
      // user/tenant取得失敗
      consola.debug('認証失敗。Auth0でログアウトします。')
      consola.debug(e)
      await goToSignIn(auth0, to.fullPath)
      return
    }
  }

  consola.debug('[authenticated] end')
})
